import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./assets/scss/style.css";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const Frame = React.lazy(() => import("./infra/Frame"));

// Pages
const Page404 = React.lazy(() => import("./views/auth/page404/Page404"));
const Page500 = React.lazy(() => import("./views/auth/page500/Page500"));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <Frame {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
